import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_IS_DOC_VERIFIER_LOADING,
  GETTER_DOC_VERIFICATION_TOKEN,
} from '../types';

export class UploadDocumentForVerification extends Action {
  _apiUrl;
  _errorHandler;

  constructor({ docVerifierApi, errorHandler }) {
    super();

    this._apiUrl = docVerifierApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit, getters }, { formData }) {
    await UploadDocumentForVerification._withLoading(async () => {
      try {
        const docVerifierToken = getters[GETTER_DOC_VERIFICATION_TOKEN];

        await fetch(`${this._apiUrl}/v1/documents`, {
          method: 'POST',
          headers: {
            'X-Verification-Session': docVerifierToken,
          },
          body: formData,
        });

        // TODO add success notification
      } catch (e) {
        this._errorHandler.handle(e, 'while execute UploadDocumentForVerification');
      }
    }, commit, MUTATION_SET_IS_DOC_VERIFIER_LOADING);
  }
}
