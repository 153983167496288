type Route = {
  PATH: string;
  NAME: string;
};

export const SERVICES_ROUTES: Record<string, Route> = {
  PREMIUM_ANALYTICS: {
    PATH: '/services/premium-analytics',
    NAME: 'premium-analytics',
  },
  PREMIUM_ANALYTICS_DIGESTS: {
    PATH: 'digests',
    NAME: 'premium-analytics-digests',
  },
  PREMIUM_ANALYTICS_EVENTS: {
    PATH: 'events',
    NAME: 'premium-analytics-events',
  },
  PREMIUM_ANALYTICS_IDEAS: {
    PATH: 'ideas',
    NAME: 'premium-analytics-ideas',
  },
  PREMIUM_ANALYTICS_DIGEST: {
    PATH: 'digests/:id',
    NAME: 'premium-analytics-digest',
  },
  PREMIUM_ANALYTICS_EVENT: {
    PATH: 'events/:id',
    NAME: 'premium-analytics-event',
  },
  PREMIUM_ANALYTICS_IDEA: {
    PATH: 'ideas/:id',
    NAME: 'premium-analytics-idea',
  },
  VPN: {
    PATH: '/services/vpn',
    NAME: 'vpn',
  },
};
