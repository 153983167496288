import {
  GETTER_2FA_STATUS,
  GETTER_IS_LOADING_STATUS,
  GETTER_IS_LOADING_UPDATE_LOCALE,
} from './types';

export default {
  [GETTER_IS_LOADING_STATUS]: ({ isLoading2fa }) => isLoading2fa,
  [GETTER_2FA_STATUS]: ({ status }) => status,
  [GETTER_IS_LOADING_UPDATE_LOCALE]: ({ isLoadingUpdateLocale }) => isLoadingUpdateLocale,
};
