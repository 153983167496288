import { USER_ROLES } from '@/constants';

export default {
  user: {
    currentUserRole: USER_ROLES.TRADER,
  },
  isUserDataLoading: false,
  partner: {},
  isPartnerDataLoading: false,
  accounts: {},
  isAccountsDataLoading: false,
  userProfile: {},
  isUserProfileLoading: false,
  isArchivedTradingAccountsLoading: false,
  archivedTradingAccounts: [],
  isTradesLoading: true,
  trades: [],
  tradesTotal: 0,
  tradesPagesCount: 1,
  ecnPromo: {},
  isEcnPromoLoading: false,
  isEnableEcnPromoLoading: false,
  applicationTitle: '',
  activeAccount: null,
  isOpenSuccessVerificationModal: false,
  walletId: 0,
  userCountry: '',
  mainChannelSocketMessages: [],
  agoraUserProfile: {},
  isAgoraUserProfileLoading: true,
};
