// module name
export const STORE_MODULE_DOC_VERIFIER = 'STORE_MODULE_DOC_VERIFIER';

// actions
export const ACTION_FETCH_START_VERIFICATION_SESSION = 'ACTION_FETCH_START_VERIFICATION_SESSION';
export const ACTION_UPLOAD_DOCUMENT_FOR_VERIFICATION = 'ACTION_UPLOAD_DOCUMENT_FOR_VERIFICATION';
export const ACTION_UPDATE_DOCUMENT = 'ACTION_UPDATE_DOCUMENT';
export const ACTION_FETCH_FINISH_VERIFICATION_SESSION = 'ACTION_FETCH_FINISH_VERIFICATION_SESSION';

// mutations
export const MUTATION_SET_IS_DOC_VERIFIER_LOADING = 'MUTATION_SET_IS_DOC_VERIFIER_LOADING';
export const MUTATION_SET_DOC_VERIFIER_TOKEN = 'MUTATION_SET_DOC_VERIFIER_TOKEN';

// getters
export const GETTER_DOC_VERIFICATION_TOKEN = 'GETTER_DOC_VERIFICATION_TOKEN';
