<template>
  <div
    id="app"
    v-set-title="applicationTitle"
    :class="rootClasses"
  >
    <v-app
      id="vuetify-app"
      class="layout-main"
    >
      <layout-init
        v-if="isLayoutInitVisible"
        :is-loading="isLoading"
        :is-error="isError"
      />
      <template v-if="isLoaded || isEmptyPage">
        <component
          :is="layoutComponent"
          :is-rtl="isRtl"
          :is-webview="isWebview()"
        />
      </template>
    </v-app>
  </div>
</template>

<script>
import { defineAsyncComponent, computed, watch, inject } from 'vue';
import { getCssClassForLocale, setTitle } from '@amarkets/ui-kit-next';
import { isEqual } from '@amarkets/atlas/esm/lib/is-equal';
import { useRoute } from 'vue-router';
import { useUserStore } from '@fsd/entities/user-profile';
import { APP_INIT_STATES, LOCALES, USER_ROLES, LAYOUT_NAMES, ROUTES } from '@/constants';
import { LayoutInit } from '@/layouts/init';
import { getAllQuery } from '@/lib/utils';
import state from '@/bootstrap/init-state-machine';
import { useLanguage } from '@/composables';

const LayoutMain = defineAsyncComponent(() => import('@/layouts/layout-main/layout-main.vue'));
const LayoutEmpty = defineAsyncComponent(() => import('@/layouts/empty/layout-empty.vue'));

export default {
  name: 'app',
  components: {
    LayoutInit,
    LayoutMain,
    LayoutEmpty,
  },
  directives: { setTitle },
  setup() {
    const route = useRoute();
    const { currentLocale, isRtl, $t } = useLanguage();
    const facadeGlobal = inject('facadeGlobal');
    const centrifuge = inject('centrifuge');
    const isWebview = inject('isWebview');

    const userStore = useUserStore();

    const isLoading = computed(() => isEqual(state.initState, APP_INIT_STATES.LOADING));
    const isError = computed(() => isEqual(state.initState, APP_INIT_STATES.ERROR));
    const isLoaded = computed(() => isEqual(state.initState, APP_INIT_STATES.LOADED));
    const userUid = computed(() => facadeGlobal.getUserUid());

    const isEmptyPage = computed(() => [ROUTES.DEBUG.NAME, ROUTES.MT_WEB.NAME].includes(route.name));
    const isLayoutInitVisible = computed(() => (isError.value || isLoading.value) && !isEmptyPage.value);

    const rootClasses = computed(() => getCssClassForLocale(currentLocale.value, LOCALES));
    const applicationTitle = computed(() => facadeGlobal.getApplicationTitle());

    const getUserRoleFromQuery = (url) => {
      const query = getAllQuery(url);
      return query?.role === USER_ROLES.PARTNER ? USER_ROLES.PARTNER : USER_ROLES.TRADER;
    };

    const role = computed(() => getUserRoleFromQuery(window.location.search));
    facadeGlobal.setCurrentUserRole(role.value);
    userStore.profile = {
      ...userStore.profile,
      role: role.value,
    };

    facadeGlobal.setApplicationTitle($t('common.document.title'));

    const layoutComponent = computed(() => {
      const LAYOUTS_COMPONENTS = {
        [LAYOUT_NAMES.MAIN]: 'layout-main',
        [LAYOUT_NAMES.EMPTY]: 'layout-empty',
      };
      return LAYOUTS_COMPONENTS[route.meta.layout] || 'layout-main';
    });

    watch(userUid, (newValue, oldValue) => {
      if (newValue && !oldValue) {
        centrifuge.subscribe(`personal:messages#${userUid.value}`, (message) => {
          facadeGlobal.addNewMessageToMainSocketChannel(message.data);
        });
      }
    });

    return {
      isWebview,
      isLoading,
      isRtl,
      isError,
      isLoaded,
      isLayoutInitVisible,
      rootClasses,
      applicationTitle,
      layoutComponent,
      isEmptyPage,
    };
  },
};
</script>

<style lang="scss">
  @import '@/assets/styles';
</style>
