/* eslint-disable no-console */
import { Centrifuge } from 'centrifuge';

export const createCentrifuge = ({ config, jwtService }) => {
  if (!config.WEBSOCKETS) {
    return false;
  }

  const instance = new Centrifuge(config.WEBSOCKETS, {
    data: { token: jwtService.accessToken },
    getData() {
      jwtService.updateToken();

      return new Promise((resolve, reject) => {
        const updatedAccessToken = jwtService.accessToken;

        if (!updatedAccessToken) {
          reject(Centrifuge.UnauthorizedError);
        }

        resolve({ token: updatedAccessToken });
      });
    },
  });

  instance.connect();

  if (!instance) {
    throw new Error('Centrifuge instance is not ready');
  }

  const subscribeToChannel = (nameChannel, callback) => {
    const subscription = instance.getSubscription(nameChannel);

    if (subscription) {
      return subscription;
    }

    const subscribe = instance.newSubscription(nameChannel);

    subscribe.subscribe();
    subscribe.on('publication', callback);

    return subscribe;
  };

  const unsubscribeFromChannel = (subscription) => {
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  return {
    instance,
    connect: () => instance.connect(),
    disconnect: () => instance.disconnect(),
    subscribe: subscribeToChannel,
    unsubscribe: unsubscribeFromChannel,
  };
};
