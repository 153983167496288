import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_ENABLE_ECN_PROMO_LOADING, ACTION_FETCH_AGORA_USER_PROFILE, ACTION_FETCH_ECN_PROMO } from '../types';

export class EnableEcnPromo extends Action {
  _api;
  _errorHandler;

  constructor({ accountApi, errorHandler }) {
    super();

    this._api = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit, dispatch }, { ecnAccountId }) {
    await EnableEcnPromo._withLoading(async () => {
      try {
        await this._api.enableEcnPromo({ ecnAccountId });
        await dispatch(ACTION_FETCH_ECN_PROMO);
        await dispatch(ACTION_FETCH_AGORA_USER_PROFILE);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute EnableEcnPromo');
      }
    }, commit, MUTATION_SET_IS_ENABLE_ECN_PROMO_LOADING);
  }
}
