import { RouteRecordRaw } from 'vue-router';
import { SERVICES_ROUTES } from '@fsd/shared/constants/routes';

export const VpnRoutes: RouteRecordRaw[] = [
  {
    name: SERVICES_ROUTES.VPN.NAME,
    path: SERVICES_ROUTES.VPN.PATH,
    component: () => import('./page-vpn.vue'),
    meta: {
      title: 'pageTitle.vpn',
    },
  },
];
