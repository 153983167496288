import { Mapper } from '@amarkets/atlas/esm/lib/mapper';
import { TRADING_ACCOUNT_TYPES } from '@/constants';

export class AgoraUserProfileMapper extends Mapper {
  _userWallet;
  _userAccounts;

  constructor(userWallet, userAccounts) {
    super();
    this._userWallet = userWallet;
    this._userAccounts = userAccounts;
  }

  mapAgoraUserProfile = (response) => ({
    wallet: AgoraUserProfileMapper._getResult(response.data.attributes, this._userWallet),
    accounts: [...response.data.attributes.accounts.data]
      .filter((account) => account.attributes.type !== TRADING_ACCOUNT_TYPES.INVEST)
      .sort(
        (firstTradingAccount, secondTradingAccount) => secondTradingAccount.attributes.equity
        - firstTradingAccount.attributes.equity,
      )
      .map(
        ({ id, attributes }) => AgoraUserProfileMapper._getResult({ id, ...attributes }, this._userAccounts),
      ),
  });
}
