import { ref, computed } from 'vue';
import { BREAKPOINT } from './const';

const width = ref(window.innerWidth);

const updateWidth = () => {
  width.value = window.innerWidth;
};

let isInitialized = false;

export function useBreakpoint() {
  if (!isInitialized) {
    window.addEventListener('resize', updateWidth);
    isInitialized = true;
  }

  const isMobile = computed(() => width.value <= BREAKPOINT.MOBILE);
  const isTablet = computed(() => width.value >= BREAKPOINT.TABLET && width.value < BREAKPOINT.DESKTOP);
  const isDesktop = computed(() => width.value >= BREAKPOINT.DESKTOP);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}
