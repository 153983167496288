import {
  USER_ROLES,
  DAYS_COUNT_WHEN_USER_CONSIDERED_NEW,
  SIMPLIFIED_DEPOSIT_COUNTRIES,
} from '@/constants';

import {
  GETTER_USER_WALLET_ID,
  GETTER_CURRENT_USER_ROLE,
  GETTER_USER_DATA,
  GETTER_IS_USER_DATA_LOADING,
  GETTER_USER_EMAIL,
  GETTER_HAS_NOT_USER_PARTNER_ROLE,
  GETTER_PARTNER_DATA,
  GETTER_IS_PARTNER_DATA_LOADING,
  GETTER_ACCOUNTS_DATA,
  GETTER_ACCOUNTS_DATA_LOADING,
  GETTER_USER_UID,
  GETTER_USER_PHONE,
  GETTER_USER_PROFILE,
  GETTER_IS_USER_PROFILE_LOADING,
  GETTER_IS_TRADES_LOADING,
  GETTER_TRADES_PAGES_COUNT,
  GETTER_TRADES,
  GETTER_TRADES_TOTAL,
  GETTER_ARCHIVED_TRADING_ACCOUNTS_LOADING,
  GETTER_ARCHIVED_TRADING_ACCOUNTS,
  GETTER_ECN_PROMO,
  GETTER_IS_ECN_PROMO_LOADING,
  GETTER_IS_ENABLE_ECN_PROMO_LOADING,
  GETTER_IS_NEW_USER_PROFILE,
  GETTER_APPLICATION_TITLE,
  GETTER_ACTIVE_ACCOUNT,
  GETTER_USER_FROM_COUNTRY_WITHOUT_REQUIRED_VERIFICATION,
  GETTER_USER_COUNTRY,
  GETTER_IS_SHOW_SUCCESS_VERIFICATION_MODAL,
  GETTER_MAIN_CHANNEL_SOCKET_MESSAGES,
  GETTER_AGORA_USER_PROFILE,
  GETTER_AGORA_USER_PROFILE_LOADING,
} from './types';

export default {
  [GETTER_USER_WALLET_ID]: ({ walletId }) => walletId,
  [GETTER_USER_COUNTRY]: ({ userCountry }) => userCountry,
  [GETTER_USER_DATA]: ({ user }) => user,
  [GETTER_IS_USER_DATA_LOADING]: ({ isUserDataLoading }) => isUserDataLoading,
  [GETTER_USER_EMAIL]: ({ user }) => user.email,
  [GETTER_USER_UID]: ({ user }) => user.userUid,
  [GETTER_USER_PHONE]: ({ user }) => user.phoneNumber,
  [GETTER_CURRENT_USER_ROLE]: ({ user }) => user.currentUserRole,
  [GETTER_HAS_NOT_USER_PARTNER_ROLE]: ({ user }) => !user.roles.includes(USER_ROLES.PARTNER),
  [GETTER_IS_PARTNER_DATA_LOADING]: ({ isPartnerDataLoading }) => isPartnerDataLoading,
  [GETTER_PARTNER_DATA]: ({ partner }) => partner,
  [GETTER_ACCOUNTS_DATA]: ({ accounts }) => accounts,
  [GETTER_ACCOUNTS_DATA_LOADING]: ({ isAccountsDataLoading }) => isAccountsDataLoading,
  [GETTER_USER_PROFILE]: ({ userProfile }) => userProfile,
  [GETTER_IS_USER_PROFILE_LOADING]: ({ isUserProfileLoading }) => isUserProfileLoading,
  [GETTER_IS_TRADES_LOADING]: ({ isTradesLoading }) => isTradesLoading,
  [GETTER_TRADES]: ({ trades }) => trades,
  [GETTER_TRADES_TOTAL]: ({ tradesTotal }) => tradesTotal,
  [GETTER_TRADES_PAGES_COUNT]: ({ tradesPagesCount }) => tradesPagesCount,
  [GETTER_ARCHIVED_TRADING_ACCOUNTS_LOADING]: ({
    isArchivedTradingAccountsLoading,
  }) => isArchivedTradingAccountsLoading,
  [GETTER_ARCHIVED_TRADING_ACCOUNTS]: ({ archivedTradingAccounts }) => archivedTradingAccounts,
  [GETTER_ECN_PROMO]: ({ ecnPromo }) => ecnPromo,
  [GETTER_IS_ECN_PROMO_LOADING]: ({ isEcnPromoLoading }) => isEcnPromoLoading,
  [GETTER_IS_ENABLE_ECN_PROMO_LOADING]: ({ isEnableEcnPromoLoading }) => isEnableEcnPromoLoading,
  [GETTER_IS_NEW_USER_PROFILE]: ({ userProfile }) => {
    const createdAtDate = new Date(userProfile.createdAt);
    const dateAfterThenUserNotNew = createdAtDate
      .setDate(createdAtDate.getDate() + DAYS_COUNT_WHEN_USER_CONSIDERED_NEW);
    const nowTime = new Date().getTime();

    return !userProfile.officeHasPayments && nowTime - dateAfterThenUserNotNew < 0;
  },
  [GETTER_APPLICATION_TITLE]: ({ applicationTitle }) => applicationTitle,
  [GETTER_ACTIVE_ACCOUNT]: ({ activeAccount }) => activeAccount,
  [GETTER_USER_FROM_COUNTRY_WITHOUT_REQUIRED_VERIFICATION]: ({ user }) => (
    SIMPLIFIED_DEPOSIT_COUNTRIES.includes(user?.country)
  ),
  [GETTER_IS_SHOW_SUCCESS_VERIFICATION_MODAL]: ({ isOpenSuccessVerificationModal }) => isOpenSuccessVerificationModal,
  [GETTER_MAIN_CHANNEL_SOCKET_MESSAGES]: ({ mainChannelSocketMessages }) => mainChannelSocketMessages,
  [GETTER_AGORA_USER_PROFILE]: ({ agoraUserProfile }) => agoraUserProfile,
  [GETTER_AGORA_USER_PROFILE_LOADING]: ({ isAgoraUserProfileLoading }) => isAgoraUserProfileLoading,
};
