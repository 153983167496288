import {
  ACTION_FETCH_START_VERIFICATION_SESSION,
  ACTION_UPLOAD_DOCUMENT_FOR_VERIFICATION,
  ACTION_UPDATE_DOCUMENT,
  ACTION_FETCH_FINISH_VERIFICATION_SESSION,
} from '../types';
import { StartVerificationSession } from './start-verification-session';
import { UploadDocumentForVerification } from './upload-document-for-verification';
import { UpdateDocument } from './update-document';
import { FinishVerificationSession } from './finish-verification-session';

export default function ({
  apiServices,
  errorHandler,
  uiNotifier,
  i18n,
  config,
}) {
  const {
    docVerifierApi,
  } = apiServices;

  const {
    API,
  } = config;
  const startVerificationSession = new StartVerificationSession({
    docVerifierApi,
    errorHandler,
    uiNotifier,
    i18n,
  });
  const uploadDocumentForVerification = new UploadDocumentForVerification({
    docVerifierApi: API.DOC_VERIFIER,
    errorHandler,
    uiNotifier,
    i18n,
  });
  const updateDocument = new UpdateDocument({
    docVerifierApi,
    errorHandler,
    uiNotifier,
    i18n,
  });
  const finishVerificationSession = new FinishVerificationSession({
    docVerifierApi,
    errorHandler,
    uiNotifier,
    i18n,
  });
  return {
    [ACTION_FETCH_START_VERIFICATION_SESSION]: startVerificationSession.execute.bind(startVerificationSession),
    [ACTION_UPLOAD_DOCUMENT_FOR_VERIFICATION]: uploadDocumentForVerification.execute
      .bind(uploadDocumentForVerification),
    [ACTION_UPDATE_DOCUMENT]: updateDocument.execute.bind(updateDocument),
    [ACTION_FETCH_FINISH_VERIFICATION_SESSION]: finishVerificationSession.execute.bind(finishVerificationSession),
  };
}
