import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_IS_DOC_VERIFIER_LOADING,
  MUTATION_SET_DOC_VERIFIER_TOKEN,
} from '../types';

export class StartVerificationSession extends Action {
  _api;
  _errorHandler;

  constructor({ docVerifierApi, errorHandler }) {
    super();

    this._api = docVerifierApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, externalToken) {
    await StartVerificationSession._withLoading(async () => {
      try {
        if (!externalToken) {
          const token = await this._api.createVerificationSession(
            ({ data }) => data.attributes.token,
          );

          await this._api.startVerificationSession(
            {
              headers: {
                'X-Verification-Session': token,
              },
            },
          );
          commit(MUTATION_SET_DOC_VERIFIER_TOKEN, token);
        } else {
          commit(MUTATION_SET_DOC_VERIFIER_TOKEN, externalToken);
        }
      } catch (e) {
        this._errorHandler.handle(e, 'while execute StartVerificationSession');
      }
    }, commit, MUTATION_SET_IS_DOC_VERIFIER_LOADING);
  }
}
