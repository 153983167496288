import { Action } from '@amarkets/atlas/esm/lib/action';
import { useUserStore } from '@fsd/entities/user-profile';
import {
  MUTATION_SET_IS_USER_PROFILE_LOADING,
  MUTATION_SET_USER_PROFILE,
} from '../types';
import { UserProfile } from '../entities/user-profile';
import { UserProfileMapper } from '../mappers/user-profile-mapper';

export class FetchUserProfile extends Action {
  _api;
  _errorHandler;

  constructor({ accountApi, errorHandler }) {
    super();
    this._api = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }) {
    await FetchUserProfile._withLoading(async () => {
      try {
        const userProfileMapper = new UserProfileMapper((opts) => new UserProfile(opts));
        const userProfile = await this._api.getUserProfile(
          (data) => userProfileMapper.mapUserProfile(data),
        );

        commit(MUTATION_SET_USER_PROFILE, userProfile);

        const userStore = useUserStore();
        userStore.profile = {
          ...userStore.profile,
          ...userProfile,
        };
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchUserProfile');
      }
    }, commit, MUTATION_SET_IS_USER_PROFILE_LOADING);
  }
}
