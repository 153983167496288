import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class AgoraWallet {
  constructor({
    wallet: {
      data: {
        attributes: {
          id,
          balance,
          currency,
        },
      },
    },
    accounts: {
      total: {
        amount,
        equity,
        dailyPnl,
      },
    },
  }) {
    Object.assign(this, {
      id,
      balance: AgoraWallet._getNumberWithCurrency(balance, currency),
      amount: AgoraWallet._getNumberWithCurrency(amount, currency),
      equity: AgoraWallet._getNumberWithCurrency(equity, currency),
      dailyPnlRaw: Number(dailyPnl),
      dailyPnl: AgoraWallet._getNumberWithCurrency(dailyPnl, currency),
    });
  }

  static _getNumberWithCurrency(number, currency) {
    return new Currency({
      value: number,
      symbol: currency,
      precision: 2,
    }).currency.format();
  }
}
