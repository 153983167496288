import { AxiosRequestConfig, AxiosError } from 'axios';
import { objectLib } from '../../../lib';

const decamelizeRequestConfig = (reqConfig: AxiosRequestConfig): AxiosRequestConfig => {
  const isFormData = reqConfig?.data instanceof FormData;

  return {
    ...reqConfig,
    params: reqConfig.params ? objectLib.decamelizeKeys(reqConfig.params) : reqConfig.params,
    data: (isFormData || !reqConfig.data) ? reqConfig.data : objectLib.decamelizeKeys(reqConfig.data),
  };
};

const rejectFinalErrorData = (err: AxiosError): Promise<AxiosError> => Promise.reject(err);

export const baseReqInterceptors = {
  success: [decamelizeRequestConfig],
  error: [rejectFinalErrorData],
};
