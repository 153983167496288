import { Action } from '@amarkets/atlas/esm/lib/action';
import {
  MUTATION_SET_IS_DOC_VERIFIER_LOADING,
  GETTER_DOC_VERIFICATION_TOKEN,
} from '../types';

export class UpdateDocument extends Action {
  _api;
  _errorHandler;

  constructor({ docVerifierApi, errorHandler }) {
    super();

    this._api = docVerifierApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit, getters }, data) {
    await UpdateDocument._withLoading(async () => {
      try {
        const docVerifierToken = getters[GETTER_DOC_VERIFICATION_TOKEN];

        await this._api.updateDocuments(
          {
            headers: {
              'X-Verification-Session': docVerifierToken,
            },
            data,
          },
          (res) => res,
        );
      } catch (e) {
        this._errorHandler.handle(e, 'while execute UpdateDocument');
      }
    }, commit, MUTATION_SET_IS_DOC_VERIFIER_LOADING);
  }
}
