export const STORE_MODULE_DEPOSIT = 'STORE_MODULE_DEPOSIT';

export const ACTION_GET_ACCOUNTS = 'ACTION_GET_ACCOUNTS';
export const ACTION_GET_PAYMENT_METHODS = 'ACTION_GET_PAYMENT_METHODS';
export const ACTION_CREATE_PAYMENT_REQUEST = 'ACTION_CREATE_PAYMENT_REQUEST';

export const MUTATION_SET_IS_LOADING = 'MUTATION_SET_IS_LOADING';
export const MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST = 'MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST';
export const MUTATION_SET_ACCOUNTS = 'MUTATION_SET_ACCOUNTS';
export const MUTATION_SET_SELECTED_ACCOUNT = 'MUTATION_SET_SELECTED_ACCOUNT';
export const MUTATION_SET_PAYMENT_METHODS = 'MUTATION_SET_PAYMENT_METHODS';
export const MUTATION_SET_SELECTED_PAYMENT_METHOD = 'MUTATION_SET_SELECTED_PAYMENT_METHOD';

export const GETTER_IS_LOADING = 'GETTER_IS_LOADING';
export const GETTER_IS_LOADING_CREATE_PAYMENT_REQUEST = 'GETTER_IS_LOADING_CREATE_PAYMENT_REQUEST';
export const GETTER_ACCOUNTS = 'GETTER_ACCOUNTS';
export const GETTER_SELECTED_ACCOUNT = 'GETTER_SELECTED_ACCOUNT';
export const GETTER_PAYMENT_METHODS = 'GETTER_PAYMENT_METHODS';
export const GETTER_SELECTED_PAYMENT_METHOD = 'GETTER_SELECTED_PAYMENT_METHOD';
export const GETTER_RECOMMENDED_PAYMENT_METHODS = 'GETTER_RECOMMENDED_PAYMENT_METHODS';
