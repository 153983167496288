import {
  MUTATION_SET_2FA_STATUS,
  MUTATION_SET_IS_LOADING_STATUS,
  MUTATION_SET_STATE_TO_DEFAULT,
  MUTATION_SET_IS_LOADING_UPDATE_LOCALE,
} from './types';
import defaultState from './state';

export default {
  [MUTATION_SET_IS_LOADING_STATUS](state, isObtaining) {
    state.isLoading2fa = isObtaining;
  },
  [MUTATION_SET_2FA_STATUS](state, status) {
    state.status = status;
  },
  [MUTATION_SET_STATE_TO_DEFAULT](state) {
    Object.assign(state, defaultState);
  },
  [MUTATION_SET_IS_LOADING_UPDATE_LOCALE](state, status) {
    state.isLoadingUpdateLocale = status;
  },
};
