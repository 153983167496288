import { DDateLocalized } from '@amarkets/atlas/esm/lib/d-date-localized';

export class GeneralItem {
  static REQUEST_TYPES = {
    DIGEST: 'digest_basic_preview',
    EVENT: 'event_basic_preview',
    IDEA: 'idea_basic_preview',
  };

  constructor({
    id,
    type,
    title,
    timestamp,
    image,
    countryFlagUrl,
    currency,
    locale,
  }) {
    const currentDate = new Date().getTime();
    const publishTime = new Date(timestamp * 1000);
    const difference = publishTime - currentDate;
    const formatTimeString = type === GeneralItem.REQUEST_TYPES.DIGEST ? 'DD MMMM, YYYY' : 'HH:mm';

    Object.assign(this, {
      id,
      type,
      ...(type !== GeneralItem.REQUEST_TYPES.EVENT && {
        image,
      }),
      bgImageStyle: {
        backgroundImage: `
          linear-gradient(180deg, rgba(0, 0, 0, 0.00) -12.22%, #131414 100%),
          url('${image}')
        `,
      },
      title,
      difference,
      formattedTime: new DDateLocalized(publishTime).format(formatTimeString, locale),
      timeFromNow: new DDateLocalized(currentDate).from(timestamp * 1000, locale),
      ...(type === GeneralItem.REQUEST_TYPES.EVENT && {
        countryFlagUrl,
        currency,
      }),
    });
  }
}
