import { RouteRecordRaw } from 'vue-router';
import { ROUTES } from '@/constants';

export const inviteFriendRoutes: RouteRecordRaw[] = [
  {
    name: ROUTES.INVITE_FRIEND.NAME,
    path: ROUTES.INVITE_FRIEND.PATH,
    component: () => import('@fsd/pages/invite-friend/index.vue'),
    children: [
      {
        name: ROUTES.INVITE_FRIEND_REFERRER.NAME,
        path: ROUTES.INVITE_FRIEND_REFERRER.PATH,
        component: () => import('@fsd/pages/invite-friend/referrer/index.vue'),
      },
      {
        name: ROUTES.INVITE_FRIEND_REFERRAL.NAME,
        path: ROUTES.INVITE_FRIEND_REFERRAL.PATH,
        component: () => import('@fsd/pages/invite-friend/referral/index.vue'),
      },
    ],
    meta: { title: 'menu.item.inviteAFriend' },
  },
  {
    name: 'ui-kit',
    path: '/ui-kit',
    component: () => import('@fsd/pages/ui-kit/index.vue'),
  },
];

export { VpnRoutes } from './vpn/routes';
