import {
  MUTATION_SET_DOC_VERIFIER_TOKEN,
  MUTATION_SET_IS_DOC_VERIFIER_LOADING,
} from './types';

export default {
  [MUTATION_SET_IS_DOC_VERIFIER_LOADING](state, isLoading) {
    state.isDocVerifierLoading = isLoading;
  },
  [MUTATION_SET_DOC_VERIFIER_TOKEN](state, docVerifierToken) {
    state.docVerifierToken = docVerifierToken;
  },
};
