import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES } from '../types';

export class AddMainChannelSocketMessage extends Action {
  // eslint-disable-next-line class-methods-use-this
  execute({ state, commit }, message) {
    const messages = [...state.mainChannelSocketMessages];
    const messageUuid = message.uuid;

    messages.push(message);
    commit(MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES, messages);

    const timeout = setTimeout(() => {
      const updatedMessages = [...state.mainChannelSocketMessages];
      const removingMessageIndex = updatedMessages.findIndex((m) => (
        m.uuid === messageUuid
      ));

      if (removingMessageIndex === -1) {
        return;
      }

      updatedMessages.splice(removingMessageIndex, 1);
      commit(MUTATION_SET_MAIN_CHANNEL_SOCKET_MESSAGES, updatedMessages);
      clearTimeout(timeout);
    }, 500);
  }
}
