import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST } from '../types';

export class CreatePaymentRequest extends Action {
  _api;
  _errorHandler;

  constructor({ depositApi, errorHandler }) {
    super();
    this._api = depositApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, payload) {
    let resp = null;

    await CreatePaymentRequest._withLoading(async () => {
      try {
        resp = await this._api.createPaymentRequest(payload);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute CreatePaymentRequest');
      }
    }, commit, MUTATION_SET_IS_LOADING_CREATE_PAYMENT_REQUEST);
    return resp;
  }
}
