import {
  ACTION_FETCH_USER_DATA,
  ACTION_FETCH_ACCOUNTS_NUMBER,
  ACTION_FETCH_PARTNER_DATA,
  ACTION_FETCH_USER_PROFILE,
  ACTION_FETCH_TRADES,
  ACTION_CHANGE_TRADING_ACCOUNT_PASSWORD,
  ACTION_ARCHIVE_TRADING_ACCOUNT,
  ACTION_UN_ARCHIVE_TRADING_ACCOUNT,
  ACTION_FETCH_ARCHIVED_TRADING_ACCOUNTS,
  ACTION_FETCH_ECN_PROMO,
  ACTION_ENABLE_ECN_PROMO,
  ACTION_CHANGE_ACCOUNT_SETTINGS,
  ACTION_FETCH_TRADES_TOTAL,
  ACTION_ADD_MAIN_CHANNEL_SOCKET_MESSAGE,
  ACTION_FETCH_AGORA_USER_PROFILE,
} from '../types';
import { FetchUserData } from './fetch-user-data';
import { FetchAccountsNumber } from './fetch-accounts-number';
import { FetchPartnerProfile } from './fetch-partner-profile';
import { FetchUserProfile } from './fetch-user-profile';
import { FetchTrades } from './fetch-trades';
import { FetchTradesTotal } from './fetch-trades-total';
import { ChangeAccountPassword } from './change-account-password';
import { ArchiveTradingAccount } from './archive-trading-account';
import { UnArchiveTradingAccount } from './un-archive-trading-account';
import { FetchArchivedTradingAccounts } from './fetch-archived-trading-accounts';
import { FetchEcnPromo } from './fetch-ecn-promo';
import { EnableEcnPromo } from './enable-ecn-promo';
import { ChangeAccountSettings } from './change-account-settings';
import { AddMainChannelSocketMessage } from './add-main-channel-socket-message';
import { FetchAgoraUserProfile } from './fetch-agora-user-profile';

export default function ({ apiServices, errorHandler, uiNotifier, i18n }) {
  const {
    accountApi,
    identifyApi,
    agoraApi,
    officeApi,
  } = apiServices;

  const fetchUserData = new FetchUserData({ identifyApi });
  const fetchAccountsNumber = new FetchAccountsNumber({ accountApi });
  const fetchPartnerProfile = new FetchPartnerProfile({ accountApi, errorHandler });
  const fetchUserprofile = new FetchUserProfile({ accountApi, errorHandler });
  const fetchTrades = new FetchTrades({ agoraApi, errorHandler });
  const fetchTradesTotal = new FetchTradesTotal({ agoraApi, errorHandler });
  const changeAccountPassword = new ChangeAccountPassword({ accountApi, errorHandler, uiNotifier, i18n });
  const archiveTradingAccount = new ArchiveTradingAccount({ officeApi, errorHandler, uiNotifier, i18n });
  const unArchiveTradingAccount = new UnArchiveTradingAccount({ officeApi, errorHandler, uiNotifier, i18n });
  const fetchArchivedTradingAccounts = new FetchArchivedTradingAccounts({ officeApi, errorHandler });
  const fetchEcnPromo = new FetchEcnPromo({ accountApi, errorHandler });
  const enableEcnPromo = new EnableEcnPromo({ accountApi, errorHandler });
  const changeAccountSettings = new ChangeAccountSettings({ accountApi, errorHandler, uiNotifier, i18n });
  const addMainChannelSocketMessage = new AddMainChannelSocketMessage({});
  const fetchAgoraUserProfile = new FetchAgoraUserProfile({ agoraApi, accountApi, errorHandler });

  return {
    [ACTION_FETCH_USER_DATA]: fetchUserData.execute.bind(fetchUserData),
    [ACTION_FETCH_ACCOUNTS_NUMBER]: fetchAccountsNumber.execute.bind(fetchAccountsNumber),
    [ACTION_FETCH_PARTNER_DATA]: fetchPartnerProfile.execute.bind(fetchPartnerProfile),
    [ACTION_FETCH_USER_PROFILE]: fetchUserprofile.execute.bind(fetchUserprofile),
    [ACTION_FETCH_TRADES]: fetchTrades.execute.bind(fetchTrades),
    [ACTION_FETCH_TRADES_TOTAL]: fetchTradesTotal.execute.bind(fetchTradesTotal),
    [ACTION_CHANGE_TRADING_ACCOUNT_PASSWORD]: changeAccountPassword.execute.bind(changeAccountPassword),
    [ACTION_ARCHIVE_TRADING_ACCOUNT]: archiveTradingAccount.execute.bind(archiveTradingAccount),
    [ACTION_UN_ARCHIVE_TRADING_ACCOUNT]: unArchiveTradingAccount.execute.bind(unArchiveTradingAccount),
    [ACTION_FETCH_ARCHIVED_TRADING_ACCOUNTS]: fetchArchivedTradingAccounts.execute.bind(fetchArchivedTradingAccounts),
    [ACTION_FETCH_ECN_PROMO]: fetchEcnPromo.execute.bind(fetchEcnPromo),
    [ACTION_ENABLE_ECN_PROMO]: enableEcnPromo.execute.bind(enableEcnPromo),
    [ACTION_CHANGE_ACCOUNT_SETTINGS]: changeAccountSettings.execute.bind(changeAccountSettings),
    [ACTION_ADD_MAIN_CHANNEL_SOCKET_MESSAGE]: addMainChannelSocketMessage.execute.bind(addMainChannelSocketMessage),
    [ACTION_FETCH_AGORA_USER_PROFILE]: fetchAgoraUserProfile.execute.bind(fetchAgoraUserProfile),
  };
}
