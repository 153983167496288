import { API_URL_EXTERNAL } from '../../consts/api-names';
import { useHttpClient } from '../../http/index';
import { GetTradingAccountsResponse } from './types';

const { get } = useHttpClient({
  baseUrl: API_URL_EXTERNAL,
});

export const useAccountApi = () => {
  const getTradingAccounts = async () => {
    const { data } = await get<GetTradingAccountsResponse>('/v1/accounts/trading');
    return { data };
  };

  return {
    getTradingAccounts,
  };
};
