import {
  FacadeGlobal,
  FacadeAuth,
  FacadeIdentity,
  FacadePaymentMethods,
} from '@/plugins/store/facades';

export function createUiServiceProvider({
  uiNotifier,
  store,
  vuetify,
  centrifuge,
  app,
  isWebview,
}) {
  const facadeAuth = new FacadeAuth(store);
  const service = {
    isRtlLayout: () => vuetify.rtl,
    isWebview: () => Boolean(isWebview),
    uiNotifier,
    facadeGlobal: new FacadeGlobal(store),
    facadeAuth,
    facadeIdentity: new FacadeIdentity(store),
    facadePaymentMethods: new FacadePaymentMethods(store),
    centrifuge,
  };

  Object.entries(service).forEach(([key, value]) => {
    app.provide(key, value);
  });
}
