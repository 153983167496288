import { Action } from '@amarkets/atlas/esm/lib/action';
import { isFunction } from '@amarkets/atlas/esm/lib/is-function';
import { AgoraUserProfile } from '../entities/agora-user-profile';
import { AgoraWallet } from '../entities/agora-wallet';
import { AgoraUserProfileMapper } from '../mappers/agora-user-profile-mapper';
import { MUTATION_SET_AGORA_USER_PROFILE, MUTATION_SET_AGORA_USER_PROFILE_LOADING } from '../types';

export class FetchAgoraUserProfile extends Action {
  _api;
  _apiLegacy;
  _errorHandler;

  constructor({ agoraApi, accountApi, errorHandler }) {
    super();

    this._api = agoraApi;
    this._apiLegacy = accountApi;
    this._errorHandler = errorHandler;
  }

  async execute({ commit }, { onSuccess } = {}) {
    await FetchAgoraUserProfile._withLoading(async () => {
      try {
        const userProfileMapper = new AgoraUserProfileMapper(
          (opts) => new AgoraWallet(opts),
          (opts) => new AgoraUserProfile(opts));

        const agoraData = await this._api.getUserProfile();
        const officeData = await this._apiLegacy.getTradingAccounts();

        agoraData.data.attributes.accounts.data = agoraData.data.attributes.accounts.data
          .filter(
            (agoraAccount) => officeData.data.some(
              (officeAccount) => agoraAccount.attributes.login === officeAccount.attributes.login,
            ))
          .map((agoraAccount) => {
            const matchingOfficeAccount = officeData.data.find(
              (officeAccount) => officeAccount.attributes.login === agoraAccount.attributes.login,
            );

            return {
              ...agoraAccount,
              attributes: {
                ...agoraAccount.attributes,
                id: matchingOfficeAccount.id,
                type: matchingOfficeAccount.attributes.type,
                platform: matchingOfficeAccount.attributes.platform,
                isEcnPromo: matchingOfficeAccount.attributes.isEcnPromo,
              },
            };
          });

        const userProfile = userProfileMapper.mapAgoraUserProfile(agoraData);

        commit(MUTATION_SET_AGORA_USER_PROFILE, userProfile);
        if (onSuccess && typeof isFunction(onSuccess)) onSuccess();
      } catch (e) {
        this._errorHandler.handle(e, 'while execute FetchAgoraUserProfile');
      }
    }, commit, MUTATION_SET_AGORA_USER_PROFILE_LOADING);
  }
}
