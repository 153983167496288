import { Action } from '@amarkets/atlas/esm/lib/action';
import { MUTATION_SET_IS_LOADING_UPDATE_LOCALE } from '../types';

export class UpdateLocale extends Action {
  _api;
  _errorHandler;
  _globalLoader;

  constructor({
    officeApi,
    errorHandler,
    globalLoader,
  }) {
    super();
    this._api = officeApi;
    this._errorHandler = errorHandler;
    this._globalLoader = globalLoader;
  }

  async execute({ commit }, { locale }) {
    await UpdateLocale._withLoading(async () => {
      try {
        await this._api.updateLocale(locale);
      } catch (e) {
        this._errorHandler.handle(e, 'while execute UpdateLocale');
      }
    }, commit, MUTATION_SET_IS_LOADING_UPDATE_LOCALE);
  }
}
