import {
  ACTION_CREATE_PAYMENT_REQUEST,
  ACTION_GET_ACCOUNTS,
  ACTION_GET_PAYMENT_METHODS,
} from '../types';
import { FetchAccounts } from './fetch-accounts';
import { FetchPaymentMethods } from './fetch-payment-methods';
import { CreatePaymentRequest } from './create-payment-request.js';

export default function ({
  apiServices,
  errorHandler,
}) {
  const { depositApi } = apiServices;
  const fetchAccounts = new FetchAccounts({
    depositApi,
    errorHandler,
  });
  const fetchPaymentMethods = new FetchPaymentMethods({
    depositApi,
    errorHandler,
  });
  const createPaymentRequest = new CreatePaymentRequest({
    depositApi,
    errorHandler,
  });

  return {
    [ACTION_GET_ACCOUNTS]: fetchAccounts.execute.bind(fetchAccounts),
    [ACTION_GET_PAYMENT_METHODS]: fetchPaymentMethods.execute.bind(fetchPaymentMethods),
    [ACTION_CREATE_PAYMENT_REQUEST]: createPaymentRequest.execute.bind(createPaymentRequest),
  };
}
