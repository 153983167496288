import { Currency } from '@amarkets/atlas/esm/lib/currency';

export class PaymentMethodItem {
  constructor(payload) {
    const {
      id,
      name,
      universalName,
      minAmount,
      maxAmount,
      group,
      iconPath,
      publicForPayment,
      publicForPaymentCountry,
      blacklistForPaymentCountry,
      currency,
      processingCurrency,
      processingTime,
      recommendedForCountry,
      blockchains,
      banks,
    } = payload;
    const currencies = [];

    if (blockchains && blockchains.length && blockchains[0]?.standard) {
      currencies.push({
        currency: blockchains[0].standard,
        color: 'warning',
      });
    }

    if (processingCurrency) {
      currencies.push({
        currency: processingCurrency,
        color: 'link',
      });
    }

    Object.assign(this, {
      id,
      name,
      universalName,
      currency,
      minAmount,
      maxAmount,
      minAmountWithCurrency: PaymentMethodItem._displayCurrency({
        value: minAmount,
        currency,
        precision: minAmount,
      }),
      maxAmountWithCurrency: PaymentMethodItem._displayCurrency({
        value: maxAmount,
        currency,
        precision: maxAmount,
        maxAmount: true,
      }),
      currencies,
      processingCurrency,
      group,
      iconPath,
      publicForPayment,
      publicForPaymentCountry,
      blacklistForPaymentCountry,
      processingTime,
      recommendedForCountry,
      banks,
    });
  }

  static _displayCurrency({
    value,
    currency,
    precision,
    maxAmount = false,
  }) {
    const isBTCCurrency = currency === 'BTC';

    if (isBTCCurrency) {
      const dollar = 'USD';
      const dollarSymbol = '$';

      return new Currency({
        value,
        symbol: dollar,
        precision: maxAmount ? Currency.getPrecision(precision) : PaymentMethodItem.countDecimals(precision),
      }).currency.format().replace(dollarSymbol, 'BTC ');
    }

    return new Currency({
      value,
      symbol: currency,
      precision: Currency.getPrecision(precision),
    }).currency.format();
  }

  static countDecimals(value) {
    const splitStr = String(value).split('.');
    return splitStr[1] ? splitStr[1].length : 0;
  }
}
